// Migrated
<template lang="pug">
.w-100(
  v-if="tabs?.length"
  ref="container",
  :class="isCharter ? 'pretty-tabs-nav-container__destinations-tabs' : 'pretty-tabs-nav-container'"
)
  TabsNav.mb-0.d-flex.pretty-tabs-nav(
    ref="tabs"
    v-bind="navTabsProps"
    @on-tab-change="onTabChange"
  )
    nuxt-link.pretty-tabs-nav-link.d-inline-flex.justify-content-center.justify-content-md-start.align-items-center.h-100.pr-0.pr-md-5.mb-0.text-center(
      v-for="(tab, tabIndex) in tabs"
      :key="tab.text"
      :to="createNuxtLinkToObj(tab)"
      :class="isActiveTab(tab.localeUrlKey) ? 'active' : 'text-muted'"
      role="tab"
      :aria-selected="isActiveTab(tab.localeUrlKey)"
    )
      .tabs-text.mb-0.font-weight-normal(
        :ref="(element) => setTabRef(tabIndex, element)"
        :data-i18n="tab?.translationKey || tab?.text"
      ) {{ $t(tab.text) }}

  .w-100.position-relative.pe-none(
    v-show="hydrated"
    style="height: 0"
  )
    svg.pretty-tabs-active-triangle-pointer.position-absolute(
      ref="activeTrianglePointer"
      viewBox="0 0 100 100"
    )
      polygon(points="50 65, 100 100, 0 100", fill="currentColor")
</template>

<script>
import TabsNav from '@layers/web/components/common/TabsNav'

export default defineNuxtComponent({
  props: {
    ...TabsNav.props,

    /**
     * @prop {Array<
     *   text: string
     *   to: string | object
     *   localeUrlKey: string
     * >} tabs
     */
    tabs: {
      type: Array,
      default: () => []
    },

    /**
     * @prop {Boolean} keepParams
     * Will spread all params into nuxt-link params, including those you specify in props.tabs[].to.query (if object)
     */
    keepQueryParams: {
      type: Boolean,
      default: false
    },

    animated: {
      type: Boolean,
      default: false
    },

    isCharter: {
      type: Boolean,
      default: false
    }
  },

  emits: ['onTabChange'],

  data () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { tabs, ...navTabsProps } = this.$props

    const activeTab = findActiveSlugKey(this.localeUrls, this.$route.params)

    return {
      navTabsProps,
      activeTab: activeTab || this.tabs?.[0]?.localeUrlKey || '',
      tabRefs: [],
      trianglePointerAnimation: 'transform 125ms cubic-bezier(.57,.21,.69,1.25)',
      hydrated: false
    }
  },

  mounted () {
    this.hydrated = true
    this.alignActiveTrianglePointer()
    window.addEventListener('resize', this.alignActiveTrianglePointer)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.alignActiveTrianglePointer)
  },

  updated () {
    this.alignActiveTrianglePointer()
  },

  methods: {
    onTabChange (newActiveTab) {
      this.activeTab = newActiveTab
      this.$emit('onTabChange', newActiveTab)
    },

    isActiveTab (tab) {
      return this.activeTab === tab
    },

    createNuxtLinkToObj (tab) {
      let to = tab.to

      if (this.keepQueryParams) {
        switch (typeof tab.to) {
          case 'object':
            to.query = {
              ...this.$route.query,
              ...(to.params || {})
            }
            break

          case 'string':
            to = {}
            to.query = this.$route.query
            to.path = tab.to
            break
        }
      }

      return to
    },

    setTabRef (tabIndex, element) {
      if (element) {
        this.tabRefs[tabIndex] = element
      }
    },

    findActiveTabElement () {
      const activeTabIndex = this.tabs.findIndex(
        tab => tab.localeUrlKey === this.activeTab
      )
      return this.tabRefs[activeTabIndex]
    },

    alignActiveTrianglePointer () {
      if (!process.browser) {
        return
      }

      const activeTabElement = this.findActiveTabElement()

      if (
        activeTabElement &&
        this.$refs.container &&
        this.$refs.activeTrianglePointer
        ) {
        if (this.animated) {
          if (this.initialized) {
            this.$refs.activeTrianglePointer.style.transition = this.trianglePointerAnimation
          } else if (!this.initialized) {
            this.initialized = true
          }
        }

        const { offsetWidth: tabWidth, offsetLeft: tabLeft } = activeTabElement
        const { offsetLeft: containerLeft } = this.$refs.container
        const { clientWidth: triangleWidth } = this.$refs.activeTrianglePointer

        this.$refs.activeTrianglePointer.style.transform = `translateX(${(tabLeft - containerLeft) + (tabWidth / 2) - (triangleWidth / 2)}px)`
      }
    },
  },
})
</script>

<style lang="scss" scoped>
$prettyNavBg: $white-blue;
.tabs-text {
  font-size: 1rem;
}
.pretty-tabs-nav-container {
  clip-path: inset(0 -50vw 0 -50vw);
  letter-spacing: 0.5px;
  max-width: 100%;

  &:before {
    content: '';
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    z-index: -1;
    background: $prettyNavBg;
  }

  .pretty-tabs-nav-link.active {
    clip-path: inset(0 -50vw);

    .tabs-text {
      font-size: 1rem;
      font-weight: bold !important;
      color: $medium-blue;
    }
  }

  @each $breakpoint in map-keys($expanded-grid-breakpoints) {
    @include media-breakpoint-up($breakpoint, $expanded-grid-breakpoints) {
      $infix: breakpoint-infix($breakpoint, $expanded-grid-breakpoints);

      &.pretty-tabs-layout#{$infix} {
        &-row {
          .pretty-tabs-nav {
            flex-direction: row;
            height: 70px;
          }

          .pretty-tabs-active-triangle-pointer {
            width: 60px;
            height: 50px;
            bottom: 0;
            color: white;
            display: block;
          }

          ul {
            a {
              padding-top: .5rem !important;
              padding-bottom: .5rem !important;
            }
          }

          .pretty-tabs-nav-link.active {
            &:before {
              display: none;
            }
          }
        }

        &-column {
          .pretty-tabs-nav {
            flex-direction: column;
            height: unset;
          }

          .pretty-tabs-active-triangle-pointer {
            display: none;
          }

          ul {
            a {
              padding-top: .5rem;
              padding-bottom: .5rem;
            }

            a:first-of-type {
              padding-top: 12px;
            }

            a:last-of-type {
              padding-bottom: 15px;
            }
          }

          .pretty-tabs-nav-link.active {
            clip-path: inset(0 -50vw);

            &:before {
              background: rgba(255,255,255,.45);
              content: '';
              left: 0;
              width: 100vw;
              height: 400px;
              position: absolute;
              z-index: -1;
            }
          }
        }
      }
    }
  }
  &__destinations-tabs {
    clip-path: inset(0 -50vw 0 -50vw);
    letter-spacing: 0.5px;
    max-width: 100%;

    &:before {
      content: '';
      height: 100vh;
      width: 100vw;
      position: absolute;
      left: 0;
      z-index: -1;
      background: $light-turquoise
    }

    .pretty-tabs-nav-link.active {
      clip-path: inset(0 -50vw);

      .tabs-text {
        font-size: 1rem;
        font-weight: bold !important;
        color: $medium-blue;
      }
    }

    @each $breakpoint in map-keys($expanded-grid-breakpoints) {
      @include media-breakpoint-up($breakpoint, $expanded-grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $expanded-grid-breakpoints);

        &.pretty-tabs-layout#{$infix} {
          &-row {
            .pretty-tabs-nav {
              flex-direction: row;
              height: 70px;
            }

            .pretty-tabs-active-triangle-pointer {
              width: 60px;
              height: 50px;
              bottom: 0;
              color: white;
              display: block;
            }

            ul {
              a {
                padding-top: .5rem !important;
                padding-bottom: .5rem !important;
              }
            }

            .pretty-tabs-nav-link.active {
              &:before {
                display: none;
              }
            }
          }

          &-column {
            .pretty-tabs-nav {
              flex-direction: column;
              height: unset;
            }

            .pretty-tabs-active-triangle-pointer {
              display: none;
            }

            ul {
              a {
                padding-top: .5rem;
                padding-bottom: .5rem;
              }

              a:first-of-type {
                padding-top: 12px;
              }

              a:last-of-type {
                padding-bottom: 15px;
              }
            }

            .pretty-tabs-nav-link.active {
              clip-path: inset(0 -50vw);

              &:before {
                background: rgba(255,255,255,.45);
                content: '';
                left: 0;
                width: 100vw;
                height: 400px;
                position: absolute;
                z-index: -1;
              }
            }
          }
        }
      }
    }
  }
}

</style>
